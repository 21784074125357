import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section className="text-center">
        <img
          alt="Cat and human sitting on a couch"
          className="block mx-auto w-1/2"
          src={catAndHumanIllustration}
        />

        <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Hey there! Welcome to the Code Practice Tutor.
        </h2>

        <div className="pb-8">
          <p className="leading-loose">
            This is a practice tool to <b className="font-bold">master technical coding interview questions</b>.
          </p>

          <p>
            Store your question bank and build mastery for problems you've seen before
          </p>
        </div>

        <button className="bg-blue-500 px-6 py-4 text-white font-bold">Prepare for your next interview now!</button>
      </section>
    </Layout>
  );
}

export default IndexPage;
